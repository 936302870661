import Favicon from './assets/favicon.ico';
import Yes from './assets/btn-yes.png';
import No from './assets/btn-no.png';
import SingleFamily from './assets/single-family.png';
import Townhouse from './assets/townhouse.png';
import MobileHome from './assets/mobile-home.png';
import Manufactured from './assets/manufactured.png';
import RegularHouse from './assets/regular-house.png';
import Excellent from './assets/credit-excellent.png';
import Good from './assets/credit-good.png';
import Average from './assets/credit-average.png';
import Fair from './assets/credit-fair.png';
import Poor from './assets/credit-poor.png';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import Checkmark from '../../../../src/images/checkmark-black.png';

export default {
  Favicon,
  Dropdown,
  yup: Yes,
  nope: No,
  'I own a home': Yes,
  "I'm looking to buy": No,
  "Yes - we're a Veteran household": Yes,
  "No - we're not a Veteran household": No,
  'Single Family': SingleFamily,
  'Townhouse/Condo': Townhouse,
  'Mobile/Trailer': MobileHome,
  'Manufactured (Permanent Foundation)': Manufactured,
  'Regular House': RegularHouse,
  Excellent,
  Good,
  Average,
  Fair,
  Poor,
  Checkmark,
};
