module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"trustedfhasurvey.com","short_name":"trustedfhasurvey.com","start_url":"/","version":"0.0.2","permissions":["gcm","storage","notifications"],"gcm_sender_id":"395966986145","gcm_user_visible_only":true,"display":"standalone","icon":"/opt/atlassian/pipelines/agent/build/data/sites/fha/trustedfhasurvey.com/assets/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b04e2bed23624e99b4bd9bd0ce782b6b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
